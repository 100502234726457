<template>
  <div class="ping-stat-container">
    <div class="ping-stat-wrapper">
      <div class="ping-stat-headers">
        <div class="ping-stat-headers-text"><a href="/">Ping Test</a></div>
        <div class="ping-stat-headers-text"><a href="http-ping">HTTP Ping</a></div>
        <div class="ping-stat-headers-text"><a href="complex-ping">Complex Ping</a></div>
      </div>
      <div class="ping-stat-info">
        <div class="ping-stat-info-header">Socket Connections</div>
        <div class="ping-stat-info-header">System Perfomance</div>
        <div class="ping-stat-info-header">Server CPU Load</div>
        <testConnections />
        <div class="ping-stat-info-row multiple-numbers">
          <testCPU />
        </div>
        <serverStat />
      </div>
    </div>
  </div>
</template>

<script>
import testConnections from "@/components/testConnections.vue";
import testCPU from "@/components/testCPU.vue";
import serverStat from "@/components/serverStat.vue";

export default {
  name: "menuPanel",
  components: {
    testConnections,
    serverStat,
    testCPU,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

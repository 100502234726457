<template>
  <div>
    Min: &nbsp;<span :style="{ color: getColor(minLoad) }">{{ minLoad }}</span>
  </div>
  <div>
    Max: <span :style="{ color: getColor(maxLoad) }">{{ maxLoad }}</span>
  </div>
  <div>
    Last: <span :style="{ color: getColor(lastLoad) }">{{ lastLoad }}</span>
  </div>
</template>

<script>
export default {
  name: "testCPU",

  data() {
    return {
      worker_cpu_test: new Worker("cputest.js"),
      minLoad: "...",
      maxLoad: "...",
      lastLoad: "...",
      firstRunPerformed: 0,
    };
  },

  created() {
    //Запускаем воркер для опроса производительности

    //Обработчик события возвращения результата из воркера
    this.worker_cpu_test.addEventListener("message", this.ProcessCpuTest, false);

    // Планируем первый запуск теста через 5 секунд после загрузки страницы.
    setTimeout(this.startComputation, 5000);
    // Дальше тест будет перезапускать сам себя.
  },

  methods: {
    startComputation() {
      //Функция запускает единичный цикл вычисления
      this.worker_cpu_test.postMessage("start");
    },

    ProcessCpuTest(test_cpu_message) {
      //Обрабатывает возвращение результатов теста CPU
      //console.log(test_cpu_message.data);

      // Skip first run result because in some browsers first run is faster
      if (this.firstRunPerformed) {
        this.lastLoad = test_cpu_message.data;
      }

      if (
        this.lastLoad > this.maxLoad ||
        (this.maxLoad === "..." && this.firstRunPerformed)
      ) {
        this.maxLoad = this.lastLoad;
      }

      if (
        this.lastLoad < this.minLoad ||
        (this.minLoad === "..." && this.firstRunPerformed)
      ) {
        this.minLoad = this.lastLoad;
      }
      this.firstRunPerformed = 1;
      setTimeout(this.startComputation, 5000);
    },
    getColor(number) {
      if (number < 8000) {
        return "#4db34d";
      }

      if (number < 16000) {
        return "#ffa300";
      }

      return "#ff0000";
    },
  },
};
</script>

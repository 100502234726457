<template>
  <div class="ping-scale-container">
    <div class="ping-scale-line first">
      <div class="ping-scale-line-left">
        <div class="ping-scale-text">
          <img
            v-if="remainingPings > 0"
            v-on:click="addPings()"
            src="img/add.svg"
            alt="Add more pings"
            width="16"
            height="16"
          />

          <img
            v-if="remainingPings === 0"
            v-on:click="startPings()"
            src="img/play.svg"
            alt="Start Ping Test"
            width="16"
            height="16"
          />
        </div>
        <div class="ping-scale-text small">{{ remainingPings }}/4000</div>
        <div class="ping-scale-progressbar">
          <div class="progressbar">
            <span :style="{ width: remainingPingsPercent }"></span>
          </div>
        </div>
        <div class="ping-scale-text">
          <img
            v-if="remainingPings > 0"
            v-on:click="stopPings()"
            src="img/close.svg"
            alt="Stop Ping Test"
            width="16"
            height="16"
          />
        </div>
      </div>
      <div class="ping-scale-line-right">
        <div class="ping-scale-header"><h1>Ping Test</h1></div>
      </div>
    </div>

    <div class="ping-scale-line">
      <div class="ping-scale-text">Success: {{ successPings }}</div>
      <div class="ping-scale-text">Lost: {{ lostPings }} ({{ lostPercent }}%)</div>
    </div>
    <div class="ping-scale-line">
      <div class="ping-scale-text">
        Delay (ms) min: {{ minDelay }}, max: {{ maxDelay }}, average:
        {{ averageDelay }}
      </div>
    </div>
    <div class="ping-scale-line">
      <div class="ping-scale-text">
        Last 30 pings average delay: {{ recentAverageDelay }} ms
      </div>
    </div>
    <div class="ping-scale">
      <div
        v-for="(time, index) in pingStatGreen"
        :key="index"
        class="ping-scale-element green"
      >
        <div class="ping-scale-element-text">{{ time }}</div>
        <div class="ping-scale-element-number next">{{ index }}</div>
      </div>
      <div
        v-for="(time, index) in pingStatYellow"
        :key="index"
        class="ping-scale-element yellow"
      >
        <div class="ping-scale-element-text">{{ time }}</div>
        <div class="ping-scale-element-number next">{{ index }}</div>
      </div>
      <div
        v-for="(time, index) in pingStatRed"
        :key="index"
        class="ping-scale-element red"
      >
        <div class="ping-scale-element-text">{{ time }}</div>
        <div class="ping-scale-element-number next">{{ index }}</div>
      </div>
      <div
        v-for="(time, index) in pingStatLast"
        :key="index"
        class="ping-scale-element red"
      >
        <div class="ping-scale-element-text">{{ time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
var Mutex = require("async-mutex").Mutex;
export default {
  name: "socketPing",

  data() {
    return {
      SOCKET_url: "wss://pingtest.online/socketping",
      pingInterval: 250,
      remainingPings: 4000,
      successPings: 0,
      lostPings: 0,
      pingTimes: { 0: 0 }, // All ping times
      pingStat: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0 }, // Ping time statictics

      recentPingStat: [],
      socket: 0,
      connectTimeout: 3000,
      mutex: new Mutex(),
    };
  },

  async mounted() {
    await this.sleep(1000);
    this.startPings();
  },

  computed: {
    remainingPingsPercent() {
      return Math.floor(this.remainingPings / 40).toString() + "%";
    },
    pingStatGreen() {
      return Object.fromEntries(Object.entries(this.pingStat).slice(1, 6));
    },

    pingStatYellow() {
      return Object.fromEntries(Object.entries(this.pingStat).slice(6, 9));
    },

    pingStatRed() {
      return Object.fromEntries(Object.entries(this.pingStat).slice(9, 10));
    },

    pingStatLast() {
      return Object.fromEntries(Object.entries(this.pingStat).slice(10, 11));
    },

    minDelay() {
      return Math.min(...Object.keys(this.pingTimes));
    },

    maxDelay() {
      return Math.max(...Object.keys(this.pingTimes));
    },

    // Average delay time for all pings from test start
    averageDelay() {
      const delaySum = Object.keys(this.pingTimes).reduce((res, delay) => {
        res += this.pingTimes[delay] * delay;
        return res;
      }, 0);

      const delayCount = Object.keys(this.pingTimes).reduce((res, delay) => {
        res += this.pingTimes[delay];
        return res;
      }, 0);

      const result = (delaySum / delayCount).toFixed(2);

      if (result === "NaN") {
        return 0;
      } else return result;
    },

    // Average delay time for last 30 pings
    recentAverageDelay() {
      const recentDelaySum = this.recentPingStat.reduce((res, delay) => {
        res += delay;
        return res;
      }, 0);

      const recentDelayCount = this.recentPingStat.length;
      const result = (recentDelaySum / recentDelayCount).toFixed(2);

      if (result === "NaN") {
        return 0;
      } else return result;
    },

    lostPercent() {
      const result = (
        this.lostPings /
        ((this.successPings + this.lostPings) / 100)
      ).toFixed(2);

      if (result === "NaN") {
        return 0;
      } else return result;
    },
  },

  methods: {
    // Main ping process
    async run(pingsNumber) {
      this.remainingPings = pingsNumber;
      this.successPings = 0;
      this.lostPings = 0;
      this.pingTimes = {};
      this.pingStat = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
      };
      this.recentPingStat = [];

      let socketIsOpened = false;

      while (this.remainingPings > 0) {
        this.remainingPings > 0 ? this.remainingPings-- : (this.remainingPings = 0);
        if (!socketIsOpened) {
          socketIsOpened = await this.openSocket();
        }

        if (socketIsOpened) {
          let pingResult = await this.socketPing().then(result => {
            return result;
          });

          if (pingResult >= 0) {
            this.pingTimes[pingResult]
              ? this.pingTimes[pingResult]++
              : (this.pingTimes[pingResult] = 1);
            this.writeRecentPingStat(this.recentPingStat, pingResult);
            this.successPings++;
            this.pingStat = this.countStat();
          } else {
            // Ping failed, socket
            this.lostPings++;

            this.socket.close();
            this.socket = null;

            socketIsOpened = false;
          }

          await this.sleep(this.pingInterval);
          //  this.remainingPings > 0 ? this.remainingPings-- : (this.remainingPings = 0);
        } else {
          // Socket was not opened
          this.remainingPings--;
          this.lostPings++;
        }
      }

      this.socket.close();
    },

    writeToSocket(message) {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(message);
        return true;
      } else {
        return false;
      }
    },

    // Add timeout to readFromSocket
    async readFromSocket() {
      let readSuccess = false;

      this.mutex.acquire().then(() => {
        // Устанавливаем функцию при таймауте
        const timer = setTimeout(() => {
          this.mutex.release();
        }, this.connectTimeout);

        this.socket.addEventListener(
          "message",
          () => {
            readSuccess = true;
            clearTimeout(timer);
            this.mutex.release();
          },
          { once: true }
        );
      });

      await this.mutex.waitForUnlock();

      return readSuccess;
    },

    async openSocket() {
      let openStatus = false;
      this.socket = new WebSocket(this.SOCKET_url);

      this.mutex.acquire().then(() => {
        // Устанавливаем функцию при таймауте
        const timer = setTimeout(() => {
          this.socket.close();
          this.socket = null;
          this.mutex.release();
        }, this.connectTimeout);

        // Вешаем обработчик события open. Предполагается, что обработчик исчезнет после удаления сокета.
        this.socket.addEventListener(
          "open",
          () => {
            openStatus = true;
            clearTimeout(timer);
            this.mutex.release();
          },
          { once: true }
        );
      });

      await this.mutex.waitForUnlock();

      return openStatus;
    },

    // Ping URL and return ping delay in ms. or -1 in case of error.
    async socketPing() {
      const timeStart = Date.now();

      this.writeToSocket("ping_message");

      const readSuccess = await this.readFromSocket();

      const timeFinish = Date.now();

      return readSuccess ? timeFinish - timeStart : -1;
    },

    // Store last 30 pings to show recent average delay time
    writeRecentPingStat(recentPingStat, pingTime) {
      if (recentPingStat.length === 30) {
        recentPingStat.shift();
        recentPingStat.push(pingTime);
      } else {
        recentPingStat.push(pingTime);
      }
    },

    countStat() {
      // Place ping times into time slots to show user-friendly stat

      //Copy pingTimes for function to work
      const pingTimesData = Object.assign({}, this.pingTimes);

      // Create time slots in stat object
      // {6: 0, 11: 0, 17: 0, 24: 0, 32: 0, 43: 0, 65: 0, 97: 0, 146: 0, 218: 0, 327: 0}
      const pingStatData = this.createTimeSlots();

      const timeIntervals = Object.getOwnPropertyNames(pingStatData).map(interval =>
        parseInt(interval)
      );

      // timeIntervals: [6, 11, 17, 24, 32, 41, 62, 93, 139, 208, 312]

      // Place ping times into slots
      Object.keys(pingTimesData).forEach(time => {
        let slotFound = false;
        timeIntervals.forEach(function (interval, index) {
          // Do not place data into first and last slot
          //          const lowBoundary = interval;
          //          const highBoundary = timeIntervals[index + 1] ? timeIntervals[index + 1] : 3000;

          if (time < interval && !slotFound) {
            slotFound = true;
            pingStatData[timeIntervals[index]] += pingTimesData[time];
          }
        });
      });

      // If >999 pings, replace with ~ 1.2k
      for (let interval in pingStatData) {
        if (pingStatData[interval] > 999) {
          pingStatData[interval] =
            (pingStatData[interval] / 1000).toFixed(1).toString() + "k";
        }
      }

      return pingStatData;
      //  always 0
      //  {"14":150,"27":8,"40":5,"53":1,"66":2,"79":4,"92":1,"105":0,"118":0,"131":1,"144":0,"9999":2}
    },

    // Count and create ping delay time intervals. Return StatData object filled with time slots
    createTimeSlots() {
      const pingStatData = {};

      // Create time slots in stat object
      // minTimeObserved 0-9: 5, 10-29: 10, 30-49: 15, 50-69: 20
      // Example slots, min time 10: 10, 20, 32, 46, 62, 80
      let timeIncrement = 5 + Math.round(this.minDelay / 20) * 5;

      // Create calculated from minTimeObserved time slots 1-8

      // Slot 1 Green
      pingStatData[this.minDelay] = 0;

      // Slot 2
      pingStatData[this.minDelay + timeIncrement] = 0;

      // Slot 3
      pingStatData[
        this.minDelay + timeIncrement * 2 + Math.ceil(timeIncrement * 0.2)
      ] = 0;

      // Slot 4
      pingStatData[
        this.minDelay + timeIncrement * 3 + Math.ceil(timeIncrement * 0.6)
      ] = 0;

      // Slot 5
      pingStatData[
        this.minDelay + timeIncrement * 4 + Math.ceil(timeIncrement * 1.2)
      ] = 0;

      // Slot 6
      pingStatData[
        this.minDelay + timeIncrement * 5 + Math.ceil(timeIncrement * 2.0)
      ] = 0;

      // Slot 7 Yellow
      pingStatData[
        Math.ceil(
          (this.minDelay + timeIncrement * 5 + Math.ceil(timeIncrement * 2.0)) * 1.5
        )
      ] = 0;

      // Slot 8
      const slot8Value = Math.ceil(
        (this.minDelay + timeIncrement * 5 + Math.ceil(timeIncrement * 2.0)) * 1.5 * 1.5
      );
      pingStatData[slot8Value] = 0;

      // Red
      // Time values of slots 9-10 can be calculated by default or
      // (if maxTimeObserved is too high) calculated from maxTimeObserved value.
      let maxDefaultSlotValue = Math.ceil(
        (this.minDelay + timeIncrement * 5 + Math.ceil(timeIncrement * 2.0)) *
          1.5 *
          1.5 *
          1.5 *
          1.5 *
          1.5
      );

      if (maxDefaultSlotValue > this.maxDelay) {
        // Default slots calculation
        // Slot 9
        pingStatData[
          Math.ceil(
            (this.minDelay + timeIncrement * 5 + Math.ceil(timeIncrement * 2.0)) *
              1.5 *
              1.5 *
              1.5
          )
        ] = 0;

        // Slot 10
        pingStatData[
          Math.ceil(
            (this.minDelay + timeIncrement * 5 + Math.ceil(timeIncrement * 2.0)) *
              1.5 *
              1.5 *
              1.5 *
              1.5
          )
        ] = 0;

        // Slot 11
        pingStatData[maxDefaultSlotValue + 1] = 0;
      } else {
        // Slots calculation based on maxTimeObserved value.
        // Slot 9
        pingStatData[Math.ceil((this.maxDelay - slot8Value) / 3 + slot8Value)] = 0;

        // Slot 10
        pingStatData[Math.ceil(((this.maxDelay - slot8Value) / 3) * 2 + slot8Value)] = 0;

        // Slot 11
        pingStatData[this.maxDelay + 1] = 0;
      }
      return pingStatData;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    addPings() {
      this.remainingPings = 4000;
    },
    stopPings() {
      this.remainingPings = 0;
    },
    startPings() {
      this.run(4000);
    },
  },
};
</script>

<template>
  <socketPing />
  <menuPanel />
  <div class="text-container">
    <div class="text-wrapper">
      <h2>Ping Test</h2>

      <p>
        Web application for continuous online Internet connection testing. Ping Test
        application establishes constant websocket connection with server and calculates
        packet transmission time from application to server and back from server to
        application. If connection can not be established or packet has not returned in 3
        seconds, it considered lost. Packet loss percent is also calculated.
      </p>
      <p>
        Classic ping utility provides very poor statistics whereas Ping Test application
        divides stat data into dynamic time slots so you can see real picture of ping
        latency distribution.
      </p>

      <p>
        Results of Ping Test via websocket protocol are similar to classic ICMP ping
        utility and mostly depends on Internet connection quality. Nonetheless, the low
        performance of device can affect test results.
      </p>

      <p>
        Using our application from mobile device, you can check quality of Wi-Fi
        connection from different locations and find optimal placement for Wi-Fi hotspot.
      </p>

      <p>
        By default, application runs 4000 pings with 0.25 second delay (so it`s more than
        15 minutes of continuous testing) and you can add more if needed.
      </p>

      <h2>Socket connections</h2>
      <p>
        Application tries to establish 50 simultaneous websocket connections to check if
        some other application or another device affects normal work by establishing too
        many network connections. If your device is unable to establish multiple TCP
        connections, it can result in unstable loading of webpages.
      </p>

      <h2>System performance</h2>
      <p>
        Very common reason of poor Internet browsing experience is high CPU load. High CPU
        load can be caused by:
      </p>
      <ul>
        <li>Unexpected behavior of some application</li>
        <li>Low total amount of system memory (8GB for Windows 10 recommended)</li>
        <li>
          Low remaining amount of system memory because of memory-consuming application.
          For example, it can be browser with too many opened tabs.
        </li>
        <li>Virus or unwanted cryptocurrency miner activity</li>
        <li>Outdated hardware</li>
      </ul>
      <p>
        Ping Test application runs periodical performance checks to estimate current CPU
        load on your device (less score is better). If score is high, it means that your
        device is busy by some other work and it can affect Internet browsing experience.
        If CPU load is high, you can get incorrect results in various Internet speedtest
        utilities.
      </p>
      <p>Performance score examples:</p>
      <table>
        <thead>
          <tr>
            <th class="cpu">CPU</th>
            <th class="year">Year</th>
            <th class="score">Score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="cpu">AMD Ryzen 5 5600X</td>
            <td class="year">2020</td>
            <td class="score">1100 - 1200</td>
          </tr>
          <tr>
            <td class="cpu">Intel Core i5-2500</td>
            <td class="year">2011</td>
            <td class="score">2200 - 2400</td>
          </tr>
          <tr>
            <td class="cpu">Qualcomm Snapdragon 845</td>
            <td class="year">2017</td>
            <td class="score">3900 - 4200</td>
          </tr>
        </tbody>
      </table>

      <h2>Server CPU load</h2>
      <p>
        Application has dedicated server to serve test connections. If server load is
        high, you can get incorrect results.
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import socketPing from "@/components/socketPing.vue";
import menuPanel from "@/components/menuPanel.vue";

export default {
  name: "HomeView",
  components: {
    socketPing,
    menuPanel,
  },
};
</script>

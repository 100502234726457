<template>
  <div
    class="ping-stat-info-row"
    :class="{
      'single-number1': successConnections < connectionsNumber,
      'single-number2': successConnections === connectionsNumber,
    }"
  >
    {{ successConnections }}/{{ connectionsNumber }}
  </div>
</template>

<script>
var Mutex = require("async-mutex").Mutex;
export default {
  name: "testConnections",

  data() {
    return {
      SOCKET_url: "wss://pingtest.online/socketping",
      connectionsNumber: 50,
      connectInterval: 20,
      connectTimeout: 3000,
      remainingConnections: 0,
      successConnections: 0,
      failedConnections: 0,
      currentSocket: 0,
      sockets: [],
      mutex: new Mutex(),
    };
  },

  created() {
    this.startConnections();
  },

  computed: {},

  methods: {
    // Main process
    async run() {
      this.remainingConnections = this.connectionsNumber;
      this.successConnections = 0;
      this.failedConnections = 0;
      this.sockets = [];

      while (this.remainingConnections > 0) {
        let openResult = await this.openSocket();

        if (openResult) {
          //this.currentSocket now contains new opened socket

          let pingResult = await this.socketPing().then(result => {
            return result;
          });

          if (pingResult >= 0) {
            this.successConnections++;
          } else {
            this.failedConnections++;
          }

          await this.sleep(this.connectInterval);
          this.remainingConnections > 0
            ? this.remainingConnections--
            : (this.remainingConnections = 0);

          this.sockets.push(this.currentSocket);
        } else {
          // Error opening socket
          this.failedConnections++;

          this.remainingConnections > 0
            ? this.remainingConnections--
            : (this.remainingConnections = 0);
        }
      }

      // Close all opened sockets at the end of test.
      this.sockets.forEach(socket => {
        socket.close();
      });
    },

    writeToSocket(message) {
      if (this.currentSocket.readyState === WebSocket.OPEN) {
        this.currentSocket.send(message);
        return true;
      } else {
        return false;
      }
    },

    async readFromSocket() {
      let readSuccess = false;

      this.mutex.acquire().then(() => {
        // Устанавливаем функцию при таймауте
        const timer = setTimeout(() => {
          this.mutex.release();
        }, this.connectTimeout);

        this.currentSocket.addEventListener(
          "message",
          () => {
            readSuccess = true;
            clearTimeout(timer);
            this.mutex.release();
          },
          { once: true }
        );
      });

      await this.mutex.waitForUnlock();

      return readSuccess;
    },

    async openSocket() {
      let openStatus = false;
      this.currentSocket = new WebSocket(this.SOCKET_url);

      this.mutex.acquire().then(() => {
        // Устанавливаем функцию при таймауте
        const timer = setTimeout(() => {
          this.currentSocket.close();
          this.currentSocket = null;
          this.mutex.release();
        }, this.connectTimeout);

        // Вешаем обработчик события open. Предполагается, что обработчик исчезнет после удаления сокета.
        this.currentSocket.addEventListener(
          "open",
          () => {
            openStatus = true;
            clearTimeout(timer);
            this.mutex.release();
          },
          { once: true }
        );
      });

      await this.mutex.waitForUnlock();

      return openStatus;
    },

    // Ping URL and return ping delay in ms. or -1 in case of error.
    async socketPing() {
      const timeStart = Date.now();

      this.writeToSocket("ping_message");

      await this.readFromSocket();

      const timeFinish = Date.now();
      return timeFinish - timeStart;
    },

    startConnections() {
      this.run();
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },
};
</script>

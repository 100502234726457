<template>
  <div
    :style="{ color: getColor(serverCpuLoad) }"
    class="ping-stat-info-row single-number2"
  >
    {{ getPercent(serverCpuLoad) }}
  </div>
</template>

<script>
export default {
  name: "serverStat",

  data() {
    return {
      RESTSTAT_url: "https://pingtest.online/reststat",
      requestActive: true,
      requestInterval: 5000,
      serverCpuLoad: 0,
    };
  },

  created() {
    this.run();
  },

  methods: {
    // Main ping process
    async run() {
      while (this.requestActive) {
        this.serverCpuLoad = await this.restStat(this.RESTSTAT_url).then(result => {
          return result;
        });

        await this.sleep(this.requestInterval);
      }
    },

    // Load URL and return CPU load or -1 in case of error.
    async restStat(restStatURL) {
      // To catch timeout
      const controller = new AbortController();

      let CPULoad = 0;

      const timeoutId = setTimeout(() => {
        controller.abort();
      }, 5000);

      await fetch(restStatURL, { signal: controller.signal })
        .then(r => r.json())
        .then(data => {
          CPULoad = data;
          clearTimeout(timeoutId);
        })
        .catch(() => {
          CPULoad = "No data";
        });
      return typeof CPULoad === "number" ? CPULoad : "No data";

      //return CPULoad;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getColor(number) {
      if (number < 60) {
        return "#4db34d";
      }

      if (number < 80) {
        return "#ffcd00";
      }
      return "#ff0000";
    },
    getPercent(percentData) {
      if (typeof percentData === "number") {
        return percentData + "%";
      } else return percentData;
    },
  },
};
</script>

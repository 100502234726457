import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/SocketPing.vue";

const routes = [
  {
    path: "/",
    name: "socket-ping",
    component: HomeView,
    meta: {
      title: "Ping Test - online check of Internet connection with packet loss test",
      metaTags: [
        {
          name: "description",
          content:
            "Continuous online ping test from your device to our server. Ping latency is similar to classic ICMP ping utility. Convenient stats, packet loss check",
        },
      ],
    },
  },

  {
    path: "/http-ping",
    name: "http-ping",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "http-ping" */ "../views/HTTPPing.vue"),
    meta: {
      title: "HTTP Ping Test online",
      link: "Some_Data",
      /*link: [
        {
          rel: "canonical",
          href: "https://pingtest.online/",
        },
      ],*/
      metaTags: [
        {
          name: "description",
          content: "Continuous Internet connection test via HTTP protocol.",
        },
      ],
    },
  },
  {
    path: "/complex-ping",
    name: "complex-ping",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "complex-ping" */ "../views/ComplexPing.vue"),
    meta: {
      title: "HTTP and Websocket Internet connection test",
      metaTags: [
        {
          name: "description",
          content:
            "Continuous Internet connection test via HTTP and Websocket protocols.",
        },
      ],
    },
  },
  {
    path: "/ru",
    name: "ru",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ru" */ "../views/SocketPingRu.vue"),
    meta: {
      title: "Ping Test - онлайн проверка Интернет подключения",
      metaTags: [
        {
          name: "description",
          content:
            "Длительная проверка подключения к Интернет от вашего устройства до нашего сервера. Удобная статистика задержка пакетов как в стандартной утилите ping.",
        },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to apply titles and descriptions /////////////////////////////////////////////////////
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(el =>
    el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

////////////////////////////////////////////////////////////////////////////////////////////////////////

export default router;
